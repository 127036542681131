














































































.filled-color {
  background-color: red;
}

.v-text-field {
  margin-bottom: -24px;
}
